import React from 'react'
import PropTypes from 'prop-types'

import ConditionalCTA from 'src/components/ConditionalCTA'
import { CenteredOnMobile, BuyNowLink, BuyNowAnchor } from '../Hero/styles'
import { ImageBackground, Container, TextBox, Text } from './styles'

const DynamicHero = ({ headline, ctaLink, ctaText, ctaAnalyticsEvent, image }) => (
  <ImageBackground src={image}>
    <Container>
      <TextBox>
        <Text dangerouslySetInnerHTML={{ __html: headline }} />
        <CenteredOnMobile>
          <ConditionalCTA
            url={ctaLink}
            text={ctaText}
            analyticsEvent={ctaAnalyticsEvent}
            anchorComponent={BuyNowAnchor}
            linkComponent={BuyNowLink}
          />
        </CenteredOnMobile>
      </TextBox>
    </Container>
  </ImageBackground>
)

DynamicHero.propTypes = {
  headline: PropTypes.string,
  ctaLink: PropTypes.string,
  ctaText: PropTypes.string,
  ctaAnalyticsEvent: PropTypes.string,
  image: PropTypes.string,
}

export default DynamicHero
