import React from 'react'
import PropTypes from 'prop-types'

import {
  FeatureContainer,
  FeatureGrid,
  GridTopLeft,
  GridTopCenter,
  GridTopRight,
  GridBottomLeft,
  GridBottomCenter,
  GridBottomRight,
} from './styles'

import { MediumHeader } from '../../utils/styles/global-styles'

const featureFrom = (feature) => (
  <>
    <h3>{feature.title}</h3>
    <p>{feature.description}</p>
  </>
)

const DynamicFeatures = ({ headline, features }) => (
  <FeatureContainer>
    <MediumHeader>{headline}</MediumHeader>
    <FeatureGrid>
      {!!features[0] && <GridTopLeft>{featureFrom(features[0])}</GridTopLeft>}
      {!!features[1] && <GridTopCenter>{featureFrom(features[1])}</GridTopCenter>}
      {!!features[2] && <GridTopRight>{featureFrom(features[2])}</GridTopRight>}
      {!!features[3] && <GridBottomLeft>{featureFrom(features[3])}</GridBottomLeft>}
      {!!features[4] && <GridBottomCenter>{featureFrom(features[4])}</GridBottomCenter>}
      {!!features[5] && <GridBottomRight>{featureFrom(features[5])}</GridBottomRight>}
    </FeatureGrid>
  </FeatureContainer>
)

DynamicFeatures.propTypes = {
  headline: PropTypes.string,
  features: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      description: PropTypes.string,
    })
  ),
}

export default DynamicFeatures
