import styled from 'styled-components'
import { breakpoints, contentPadding, ContentContainer } from 'src/utils/styles/global-styles'

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const ImageBackground = styled.div`
  background-image: url(${(props) => props.src});
  display: flex;
  flex-direction: column;
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 1;
  height: 70vh;
  ${contentPadding}
  padding-top: 0;
  padding-bottom: 0;
  @media (max-width: ${breakpoints.m}px) {
    height: 400px;
    background-repeat: no-repeat;
    background-size: auto 200px;
    padding-top: 200px;
  }
`

export const TextBox = styled(ContentContainer)`
  > * {
    margin-top: 0;
  }
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  font-weight: normal;
  padding: 8rem 2rem;
  margin: 0;
  max-width: 40vw;
  @media (max-width: ${breakpoints.l}px) {
    max-width: 50vw;
  }
  @media (max-width: ${breakpoints.m}px) {
    padding: 0;
    max-width: 100vw;
    align-items: center;
  }
`

export const Text = styled.h1`
  color: '#444444';
  font-size: 3.5rem;
  font-weight: normal;
  line-height: 1.25;
  width: 100%;
  @media (max-width: 1650px) {
    font-size: 3.25rem;
  }
  @media (max-width: 1500px) {
    font-size: 2.4rem;
  }
  @media (max-width: ${breakpoints.xl}px) {
    font-size: 2rem;
  }
  @media (max-width: ${breakpoints.m}px) {
    font-size: 1.75rem;
  }
`
