import styled, { css } from 'styled-components'
import { breakpoints } from 'src/utils/styles/global-styles'

export const PressContainer = styled.div`
  max-width: 1020px;
  padding: 50px 0px 80px;
  margin: 0 auto;
  @media (max-width: ${breakpoints.s}px) {
    padding: ${(props) => (props.smallerPadding ? '20px 0px 0px' : '20px 0 50px')};
  }
`

export const PressRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  flex-basis: 100%;
  max-width: 800px;
  margin: 50px auto 0px;
  @media (max-width: ${breakpoints.l}px) {
    max-width: 90%;
  }
  @media (max-width: 800px) {
    display: block;
    text-align: center;
    max-width: 100%;
    img {
      margin-bottom: 60px;
    }
  }
`

export const logoDividerCss = css`
  width: 50%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  @media (max-width: 800px) {
    width: 75%;
  }
`

export const RowTopOnMobile = styled.div`
  ${logoDividerCss}
  > * {
    margin-right: 3rem;
  }
  @media (max-width: 800px) {
    margin-bottom: 2rem;
    > * {
      margin: 0 0.5rem 1rem;
    }
    > :first-child {
      margin-right: 3rem;
    }
  }
`

export const RowBottomOnMobile = styled.div`
  ${logoDividerCss}
  > :first-child {
    margin-right: 3rem;
  }
  @media (max-width: 800px) {
    > :first-child {
      margin: 0;
      margin-right: 3rem;
    }
  }
`

export const LogoContainer = styled.div`
  width: 100%;
  text-align: center;
`
