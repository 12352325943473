import React from 'react'
import PropTypes from 'prop-types'

import YotpoStars from 'src/components/YotpoStars'
import theme from 'src/utils/styles/theme'

import ConditionalCTA from 'src/components/ConditionalCTA'
import {
  Container,
  VideoContainer,
  HeroVideo,
  TextBox,
  Text,
  CenteredOnMobile,
  BuyNowLink,
  BuyNowAnchor,
} from '../Hero/styles'

const DynamicVideoHero = ({
  headline,
  ctaLink,
  ctaText,
  video,
  ctaAnalyticsEvent,
  includeStars,
}) => (
  <Container>
    <TextBox>
      {!!includeStars && <YotpoStars color={theme.gold} textcolor="#444444" />}
      <Text dangerouslySetInnerHTML={{ __html: headline }} />
      <CenteredOnMobile>
        <ConditionalCTA
          url={ctaLink}
          text={ctaText}
          analyticsEvent={ctaAnalyticsEvent}
          anchorComponent={BuyNowAnchor}
          linkComponent={BuyNowLink}
        />
      </CenteredOnMobile>
    </TextBox>
    <VideoContainer>
      <HeroVideo playsInline autoPlay muted loop>
        <source src={video} type="video/mp4" />
      </HeroVideo>
    </VideoContainer>
  </Container>
)

DynamicVideoHero.propTypes = {
  headline: PropTypes.string,
  ctaLink: PropTypes.string,
  ctaText: PropTypes.string,
  ctaAnalyticsEvent: PropTypes.string,
  video: PropTypes.string,
  includeStars: PropTypes.bool,
}

export default DynamicVideoHero
