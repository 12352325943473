import styled from 'styled-components'

import { breakpoints } from 'src/utils/styles/global-styles'

export const FAQContainer = styled.div`
  margin: 80px;
  text-align: center;
  @media (max-width: 800px) {
    margin: 80px 20px;
  }
`
export const Header = styled.h2`
  text-align: center;
  margin: 40px;
  font-size: 2.5em;
  @media (max-width: ${breakpoints.l}px) {
    font-size: 1.5em;
  }
`
export const FAQ = styled.div`
  margin: 40px 0;
  font-size: 20px;
`

export const Question = styled.h3`
  font-weight: 700;
  margin-bottom: 0px;
`

export const Answer = styled.p`
  margin-top: 0px;
`
export const ALink = styled.a`
  color: red;
  text-decoration: none;
  cursor: pointer;
  &:hover,
  &:focus {
    outline: none;
  }
`
