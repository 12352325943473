import React from 'react'
import PropTypes from 'prop-types'

import Analytics from 'src/utils/Analytics'

import LoadingAnimation from 'src/components/LoadingAnimation'
import SEO from 'src/components/seo'
import DynamicHero from 'src/components/DynamicHero'
import DynamicVideoHero from 'src/components/DynamicVideoHero'
import DynamicGifTiles from 'src/components/DynamicGifTiles'
import DynamicFeatures from 'src/components/DynamicFeatures'
import DynamicPress from 'src/components/DynamicPress'
import DynamicTestimonials from 'src/components/DynamicTestimonials'
import TVFAQs from 'src/components/TVFAQs'
import ConditionalCTA from 'src/components/ConditionalCTA'

import { CenteredContent, BuyNowLink, BuyNowAnchor } from 'src/components/Hero/styles'
import { ContentContainer } from 'src/utils/styles/global-styles'

class LandingPage extends React.Component {
  componentDidMount() {
    const { pageContext } = this.props
    if (!pageContext?.name) return
    Analytics.track(`Viewed ${pageContext.name}`)
  }

  render() {
    const { pageContext } = this.props
    if (!pageContext?.fields) return <LoadingAnimation />
    const {
      slug,
      fields: {
        seo,
        hero,
        quote,
        left_gif,
        right_gif,
        final_left_gif,
        feature_headline,
        features,
        press,
        testimonials,
        faqs,
      },
    } = pageContext

    return (
      <>
        <SEO title={seo.title} description={seo.meta_description} path={`/${slug}`} />
        {!!hero.video_url ? (
          <DynamicVideoHero
            headline={hero.headline}
            video={hero.video_url}
            ctaText={hero.cta_button_text}
            ctaLink={hero.cta_button_link}
            ctaAnalyticsEvent={hero.cta_analytics_event}
            includeStars={hero.include_stars}
          />
        ) : (
          <DynamicHero
            headline={hero.headline}
            image={hero.image}
            ctaText={hero.cta_button_text}
            ctaLink={hero.cta_button_link}
            ctaAnalyticsEvent={hero.cta_analytics_event}
          />
        )}
        <ContentContainer>
          <DynamicGifTiles
            quote={quote}
            leftGif={left_gif}
            rightGif={right_gif}
            finalLeftGif={final_left_gif}
          />
        </ContentContainer>
        <DynamicFeatures headline={feature_headline} features={features} />
        <ContentContainer>
          <DynamicPress press={press} />
          <DynamicTestimonials testimonials={testimonials} />
          <TVFAQs questions={[{ sectionTitle: 'HOW IT WORKS', content: faqs }]} />
          <CenteredContent>
            <ConditionalCTA
              text={hero.cta_button_text}
              url={hero.cta_button_link}
              analyticsEvent={hero.cta_analytics_event}
              anchorComponent={BuyNowAnchor}
              linkComponent={BuyNowLink}
            />
          </CenteredContent>
        </ContentContainer>
      </>
    )
  }
}

LandingPage.propTypes = {
  pageContext: PropTypes.shape({
    slug: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    fields: PropTypes.shape({
      seo: PropTypes.shape({
        title: PropTypes.string.isRequired,
        meta_description: PropTypes.string.isRequired,
      }),
      hero: PropTypes.shape({
        headline: PropTypes.string.isRequired,
        image: PropTypes.string.isRequired,
        cta_button_link: PropTypes.string.isRequired,
        cta_button_text: PropTypes.string.isRequired,
        cta_analytics_event: PropTypes.string,
        video_url: PropTypes.string,
        include_stars: PropTypes.bool,
      }),
      quote: PropTypes.string,
      left_gif: PropTypes.shape({
        gif: PropTypes.string.isRequired,
        text: PropTypes.string.isRequired,
      }),
      right_gif: PropTypes.shape({
        gif: PropTypes.string.isRequired,
        text: PropTypes.string.isRequired,
      }),
      final_left_gif: PropTypes.shape({
        gif: PropTypes.string.isRequired,
        text: PropTypes.string.isRequired,
      }),
      feature_headline: PropTypes.string,
      features: PropTypes.arrayOf(
        PropTypes.shape({
          title: PropTypes.string,
          description: PropTypes.string,
        })
      ),
      press: PropTypes.arrayOf(
        PropTypes.shape({
          link: PropTypes.string,
          image: PropTypes.string,
          publication: PropTypes.string,
        })
      ),
      testimonials: PropTypes.arrayOf(
        PropTypes.shape({
          quote: PropTypes.string,
          customer_name: PropTypes.string,
        })
      ),
      faqs: PropTypes.arrayOf(
        PropTypes.shape({
          q: PropTypes.string,
          a: PropTypes.string,
        })
      ),
    }),
  }),
}

export default LandingPage
