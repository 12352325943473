import styled from 'styled-components'

export const TestimonialContainer = styled.div`
  padding: 60px 120px 80px;
  @media (max-width: 600px) {
    padding: 60px 40px 80px;
  }
  border-top: 1px solid ${(props) => props.theme.backgroundLight};
  border-bottom: 1px solid ${(props) => props.theme.backgroundLight};
`

export const TestimonialGrid = styled.div`
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 30px 1fr;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 30px;
  grid-auto-rows: minmax(100px, auto);
  font-size: 18px;
  font-weight: 400;
  @media (max-width: 1200px) {
    display: block;
  }
`

export const CustomerName = styled.p`
  color: ${(props) => props.theme.textDarker};
  font-weight: 400;
  margin-bottom: 50px;
`

export const GridTopLeft = styled.div`
  grid-column: 1;
  grid-row: 1;
  -ms-grid-column: 1;
  -ms-grid-row: 1;
`

export const GridTopRight = styled.div`
  grid-column: 2;
  grid-row: 1;
  -ms-grid-column: 3;
  -ms-grid-row: 1;
`

export const GridCenterLeft = styled.div`
  grid-column: 1;
  grid-row: 2;
  -ms-grid-column: 1;
  -ms-grid-row: 2;
`

export const GridCenterRight = styled.div`
  grid-column: 2;
  grid-row: 2;
  -ms-grid-column: 3;
  -ms-grid-row: 2;
`
export const GridBottomLeft = styled.div`
  grid-column: 1;
  grid-row: 3;
  -ms-grid-column: 1;
  -ms-grid-row: 3;
`
export const GridBottomRight = styled.div`
  grid-column: 2;
  grid-row: 3;
  -ms-grid-column: 3;
  -ms-grid-row: 3;
`
export const Text = styled.p`
  display: inline;
`
