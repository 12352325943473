import styled from 'styled-components'

export const FeatureContainer = styled.div`
  padding: ${(props) => (props.features ? '50px 0px 70px' : '70px 0px')};
  background-color: ${(props) => props.bgColor};
  @media (max-width: 1330px) {
    padding: ${(props) => (props.features ? '20px 0px 40px' : '70px 100px')};
  }
  @media (max-width: 600px) {
    padding: ${(props) => (props.features ? '20px 0px 40px' : '60px 20px 80px')};
  }
`
export const FeatureGrid = styled.div`
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 30px 1fr 30px 1fr;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 30px;
  grid-auto-rows: minmax(100px, auto);
  align-items: center;
  text-align: center;
  @media (max-width: 1200px) {
    display: block;
  }
`

export const GridTopLeft = styled.div`
  grid-column: 1;
  grid-row: 1;
  -ms-grid-column: 1;
  -ms-grid-row: 1;
`

export const GridTopCenter = styled.div`
  grid-column: 2;
  grid-row: 1;
  -ms-grid-column: 3;
  -ms-grid-row: 1;
`

export const GridTopRight = styled.div`
  grid-column: 3;
  grid-row: 1;
  -ms-grid-column: 5;
  -ms-grid-row: 1;
`

export const GridBottomLeft = styled.div`
  grid-column: 1;
  grid-row: 2;
  -ms-grid-column: 1;
  -ms-grid-row: 2;
`

export const GridBottomCenter = styled.div`
  grid-column: 2;
  grid-row: 2;
  -ms-grid-column: 3;
  -ms-grid-row: 2;
`

export const GridBottomRight = styled.div`
  grid-column: 3;
  grid-row: 2;
  -ms-grid-column: 5;
  -ms-grid-row: 2;
`
