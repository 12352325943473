import React from 'react'
import PropTypes from 'prop-types'

import { Header, FAQ, Question, Answer, ALink } from './styles'

const FAQs = ({ title, questions }) => (
  <div>
    <Header>{title || 'Frequently Asked Questions'}</Header>
    {questions.map((section) => (
      <React.Fragment key={section.sectionTitle}>
        {section.sectionTitle && <h3>{section.sectionTitle}</h3>}
        {section.content.map((content, i) => (
          <FAQ key={i + content.q[0]}>
            <Question>{content.q}</Question>
            <Answer>
              {content.a}
              <ALink href={content.link} target="_blank">
                {content.text}
              </ALink>
              {content.p}
            </Answer>
          </FAQ>
        ))}
      </React.Fragment>
    ))}
  </div>
)

FAQs.propTypes = {
  questions: PropTypes.array.isRequired,
  title: PropTypes.string,
}

export default FAQs
