import React from 'react'
import PropTypes from 'prop-types'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'

import {
  TwoColumnGrid,
  GridLeft,
  GridRight,
  HiddenOnDesktop,
  HiddenOnMobile,
} from 'src/utils/styles/global-styles'
import { GifRowContainer, CenteredGridLeft, Header } from './styles'

const DynamicGifTiles = ({ quote, leftGif, rightGif, finalLeftGif }) => {
  const { statisfactionImg } = useStaticQuery(graphql`
    query DynamicGifTilesQuery {
      statisfactionImg: file(relativePath: { eq: "money-back-guarantee.png" }) {
        childImageSharp {
          gatsbyImageData(width: 255, layout: FIXED, placeholder: NONE)
        }
      }
    }
  `)

  return (
    <>
      {!!leftGif.text && !!leftGif.gif && (
        <GifRowContainer>
          <Header>{quote}</Header>
          <TwoColumnGrid>
            <GridLeft>
              <img src={leftGif.gif} alt="Perfect gift" />
            </GridLeft>
            <GridRight>
              <div dangerouslySetInnerHTML={{ __html: leftGif.text }} />
            </GridRight>
          </TwoColumnGrid>
        </GifRowContainer>
      )}

      {!!rightGif.text && !!rightGif.gif && (
        <GifRowContainer>
          <TwoColumnGrid>
            <HiddenOnDesktop>
              <img src={rightGif.gif} alt="Effortless to use" />
            </HiddenOnDesktop>
            <GridLeft>
              <div dangerouslySetInnerHTML={{ __html: rightGif.text }} />
            </GridLeft>
            <GridRight>
              <HiddenOnMobile>
                <img src={rightGif.gif} alt="Effortless to use" />
              </HiddenOnMobile>
            </GridRight>
          </TwoColumnGrid>
        </GifRowContainer>
      )}

      {!!finalLeftGif.gif && !!finalLeftGif.text && (
        <GifRowContainer>
          <TwoColumnGrid>
            <GridLeft>
              <img src={finalLeftGif.gif} alt="Satisfaction Guaranteed" />
            </GridLeft>
            <GridRight>
              <div dangerouslySetInnerHTML={{ __html: finalLeftGif.text }} />
            </GridRight>
          </TwoColumnGrid>
        </GifRowContainer>
      )}
      <br />
      <br />
      <GifRowContainer>
        <TwoColumnGrid>
          <CenteredGridLeft>
            <GatsbyImage
              image={statisfactionImg.childImageSharp.gatsbyImageData}
              alt="Money Back Guarantee"
            />
          </CenteredGridLeft>
          <GridRight>
            <h2>100% Satisfaction Guaranteed</h2>
            <p>
              With tens of thousands of happy customers, we're confident you'll love your Skylight.
              That's why we offer a 100% Satisfaction Guarantee -- or your money back!
            </p>
          </GridRight>
        </TwoColumnGrid>
      </GifRowContainer>
    </>
  )
}

DynamicGifTiles.propTypes = {
  quote: PropTypes.string,
  leftGif: PropTypes.shape({
    text: PropTypes.string,
    gif: PropTypes.string,
  }),
  rightGif: PropTypes.shape({
    text: PropTypes.string,
    gif: PropTypes.string,
  }),
  finalLeftGif: PropTypes.shape({
    text: PropTypes.string,
    gif: PropTypes.string,
  }),
}

export default DynamicGifTiles
