import React from 'react'
import PropTypes from 'prop-types'

import {
  TestimonialContainer,
  TestimonialGrid,
  GridTopLeft,
  GridTopRight,
  GridBottomLeft,
  GridBottomRight,
  CustomerName,
} from '../Testimonials/styles'

import { MediumHeader } from '../../utils/styles/global-styles'

const testimonialFrom = (testimonial) => (
  <>
    <div dangerouslySetInnerHTML={{ __html: testimonial.quote }} />
    <CustomerName>– {testimonial.customer_name}</CustomerName>
  </>
)

const Testimonials = ({ testimonials }) => (
  <TestimonialContainer>
    <MediumHeader>USERS RAVE ABOUT SKYLIGHT</MediumHeader>
    <TestimonialGrid>
      {!!testimonials[0] && <GridTopLeft>{testimonialFrom(testimonials[0])}</GridTopLeft>}
      {!!testimonials[1] && <GridTopRight>{testimonialFrom(testimonials[1])}</GridTopRight>}
      {!!testimonials[2] && <GridBottomLeft>{testimonialFrom(testimonials[2])}</GridBottomLeft>}
      {!!testimonials[3] && <GridBottomRight>{testimonialFrom(testimonials[3])}</GridBottomRight>}
    </TestimonialGrid>
  </TestimonialContainer>
)

Testimonials.propTypes = {
  testimonials: PropTypes.arrayOf(
    PropTypes.shape({
      quote: PropTypes.string,
      customer_name: PropTypes.string,
    })
  ),
}

export default Testimonials
